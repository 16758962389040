import React from 'react';
import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default withAuth(
class ProfileLanding extends React.Component {
  constructor(props) {
    super(props);
	  
    this.state = {
      error: null,
      isLoaded: false,
      redirect: false,
      data: "",
      interstitial: "default",
      user: null,
      userId: ''
    }
	  
  }
	//DEFINE what to do when the component is loaded
	componentDidMount() {
	this.getCurrentUser();
	  }
	
	//CREATE AN ASYNC function for getting the current user information 
      async getCurrentUser() {
      this.props.auth.getUser().then(user => { 
	this.setState({ user }) 
	  this.getUserGroups() 
        })
        }

	//DEFINE the get groups function, append it to the getCurrentUser async function
	getUserGroups(){
	//FETCH getgroups, pass the user retrieved within the async getCurrentUser call	
	  fetch('/api/getgroups', {
        method: 'POST',
        body: JSON.stringify({
       user: this.state.user
   	 }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
	      .then(res => res.json())
	      .then(res =>{
	    console.log(res)
	   if(res.a=="confirmed"){
		   this.setState({ 
			     isLoaded: true,
			     redirect: true     })
	      }else{
		      this.setState({ 
			     isLoaded: true	})
	      }
	    /* this.setState({ isLoaded: true,
			     redirect: true	})*/
	         
      }) 
	     
	    
	  .catch(err => {
          this.setState({ error: err.message })
          console.log(err.statusCode + ' error', err)
        })	
		
		
		
/*fetch('/api/getgroups', {
        method: 'POST',
       body: JSON.stringify({
       user: this.state.user
    }),
    headers: {"Content-Type": "application/json"}
  }).then(res => res.json())
  .then(res => console.log(res))
      //.then(res => res.json())
     
      
		
	
   
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
          .catch(error => this.setState({
            isLoaded: true,
            error
          }) )
          
        }
      )*/

	
}


  render() {
	  
    const { error, isLoaded } = this.state;
    const { redirect } = this.state;

     if (!isLoaded) {
      var cb = "background-container-" + Math.round( Math.random()*4) +" profile";
      return (

          <Loader
        type="TailSpin"
        color="#00BFFF"
        height={600}
        width={125}
        timeout={40000} //3 secs
      />
      );
	
	     
     } else {

 if (redirect) {
     return <Redirect to='/asset_portal_login'/>;
}else{
     return (
	     
        <section className="user-profile">
         <div className={cb}>
        <div class="form-container">
   <div className="form-element logo-element">
	      <p className="logo">&nbsp;</p>
  </div>
	<div className="interstitial-text nonmember">
          <h1>Thank you for registering!</h1>
	  <h2>Check your email for next steps.</h2>
          <div>
       </div>
           
         

        <a href="javascript:void(0)" onClick={() => this.props.auth.logout()}> Logout </a>
          </div>
  </div>
</div>
        </section>
	     );
}

     
    }

  } 
}
	);
