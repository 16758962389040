import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import Logout from './Logout';



  export default withAuth( class RecoveryComplete extends React.Component {
   constructor(props) {
      super(props);
 
    }
	  
	  
    render() {
    if (this.props.auth.isPending) {
      return <div>Loading...</div>;
    }

    const button = this.props.auth.isAuthenticated ?
      <a onClick={() => {this.props.auth.logout()}}>Login</a> :
      <a onClick={() => {this.props.history.push('/profilelanding')}}>Logout</a>;

    return (
	    
	    
	    <section className="user-profile">
         <div className={"background-container-" + Math.round( Math.random()*4) +" profile complete"}>
        <div class="form-container">
   <div className="form-element logo-element">
	      <p className="logo">&nbsp;</p>
  </div>
	
          <h1>Your password has been reset!</h1>
	
	  <h2>You can now  <a href="/profilelanding">Login</a>.</h2>
          <div>
           
         


          </div>
  </div>
</div>
        </section> 

    );
  }	  
	  
    /*render() {
    
      return (
        <section className="user-profile">
         <div className={"background-container-" + Math.round( Math.random()*4) +" profile complete"}>
        <div class="form-container">
   <div className="form-element logo-element">
	      <p className="logo">&nbsp;</p>
  </div>
	
          <h1>Your password has been reset!</h1>
<button onClick={() => {this.props.oktaAuth.signOut()}}>Logout</button>
	  <h2>You can now <a href="/logout">login</a>.<p>You will receive a confirmation via email.</p></h2>
          <div>
           
         


          </div>
  </div>
</div>
        </section>
      );
    }*/
  });

