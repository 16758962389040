
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import { withAuth } from '@okta/okta-react';



export default withAuth(class Logout extends Component {
  constructor(props) {
    super(props);
   
    this.state = { authenticated: null };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.checkAuthentication();
  }

  async checkAuthentication() {
    
    const authenticated = await this.props.auth.isAuthenticated();

    if (authenticated !== this.state.authenticated) {
      
      this.setState({ authenticated });
    }
  }

  componentDidUpdate() {
   this.checkAuthentication();
    this.props.auth.logout();
    if(!window.location.hash) {
		window.location = window.location + '#loaded';
		window.location.reload();
	}
  
  }
  
  render() {
    
   if (this.state.authenticated === null) return null;
	  
   this.props.auth.logout();

   return this.state.authenticated ?
    <Redirect to={{ pathname: '/profilelanding' }} />
      :
      <LoginForm baseUrl={this.props.baseUrl} />;
  }
});

/*
import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

// Basic component with logout button
class Logout extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
	
componentDidUpdate() {

  
    if(!window.location.hash) {
		window.location = window.location + '#loaded';
		window.location.reload();
	}
  
  }

  logout() {
   this.props.auth.logout();
    sessionStorage.clear();
     localStorage.clear();
  }

  render() {
    return (
   <>  {this.logout()} </>
    );
  }
};

// withOktaAuth() makes Okta "oktaAuth" object available
// as "this.props.oktaAuth"
Logout = withAuth(Logout);
export default Logout;
*/
