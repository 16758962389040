import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { SecureRoute, ImplicitCallback } from '@okta/okta-react';


import Navigation from './components/shared/Navigation';
import HomePage from './components/home/HomePage';
import RegistrationForm from './components/auth/RegistrationForm';
import config from './app.config';
import LoginPage from './components/auth/LoginPage';
import ProfileLanding from './components/auth/ProfileLanding';
import ProfilePage from './components/auth/ProfilePage';
import PasswordReset from './components/auth/ForgotPassword';
import RecoveryConfirm from './components/auth/RecoveryConfirm';
import RecoveryComplete from './components/auth/RecoveryComplete';
import PasswordRecovery from './components/auth/PasswordRecovery';
import Logout from './components/auth/Logout';
import './App.css';

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Navigation />
        <main>
          <Route path="/" exact component={HomePage} />
          <Route
            path="/login"
            render={() => <LoginPage baseUrl={config.url} />}
          />
          <Route path="/implicit/callback" component={ImplicitCallback} />
          <Route path="/register" component={RegistrationForm} />
            <Route path="/recoverycomplete" component={RecoveryComplete} />
          <SecureRoute path="/profile" component={ProfilePage} />
            <SecureRoute path="/profilelanding" component={ProfileLanding} />
           <Route path="/passwordreset" component={PasswordReset} />
              <Route path="/recoveryconfirm" component={RecoveryConfirm} />
               <SecureRoute path="/logout" component={Logout} /> 
                <Route path="/passwordrecovery" component={PasswordRecovery} />
                  <Route path='/asset_portal_login' component={() => { 
                   window.location.href = 'https://www.allofuscomms.org/moLogin'; 
                   return null;
                    }}/>
        </main>
      </div>
    );
  }
}
