import React from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { withAuth } from '@okta/okta-react';
import Logout from './Logout';

import config from '../../app.config';
var randomBackground = "background-container-" + Math.round( Math.random()*4) +" register";

export default withAuth(
  class ForgotPassword extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
	error: null,
        email: '',
        sessionToken: null
      };
        
      this.oktaAuth = new OktaAuth({ url: config.url });
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication();

      this.handleSubmit = this.handleSubmit.bind(this);
  
      this.handleEmailChange = this.handleEmailChange.bind(this);
   
    }

    async checkAuthentication() {
      const sessionToken = await this.props.auth.getIdToken();
      if (sessionToken) {
        this.setState({ sessionToken });
      }
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    handleEmailChange(e) {
      this.setState({ email: e.target.value });
    }
    
    
   
    handleSubmit(e) {
      e.preventDefault();
      fetch('/api/userfind', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
	      
	      .then(res =>{
	      if (res.ok) {

	this.props.history.push('/RecoveryConfirm')
         return res.json();
		      
       } else {
          throw new Error('Something went wrong ...');
	   console.log("ERROR");
       }
          
      }) 
	     
	    
	     .catch(err => {
          this.setState({ error: err.message });
          console.log(err.statusCode + ' error', err);
        });
	    
        
        }
      
    

    render() {
      if (this.state.sessionToken) {
        this.props.auth.redirect({ sessionToken: this.state.sessionToken });
        return null;
      }
	    
	     const errorMessage = this.state.error ? (
       <div><span className="error-message">{this.state.error}</span> <p className="error-subtext">Please check your email address and password and try again.</p></div>
      ) : null;

      return (
	      <div className={randomBackground}>
	      <div className="form-container">
        <form onSubmit={this.handleSubmit}>
	      <div className="form-element logo-element">
	      <p className="logo">&nbsp;</p>
	      
	      <p>Recover Password</p>
	      {errorMessage}
		<p></p>
	     <span className="email-note">Enter the email address associated with your AoU account.</span>
	      </div>
	    
          <div className="form-element recovery-email">
	        
            <label>Email:</label>
            <input
              type="email"
              id="email"
              placeholder="Email*"
	      value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
	
	    <div className="form-element submit-element">
              <span>* indicates a required field</span>
          <input type="submit" id="submit" value="Begin Password Recovery" />
		  
		   <span className="login">Back to <a
              href="/profilelanding"
             
            >
              Login
            </a></span>
		  </div>
        </form>
	    </div>
	    </div>
      );
    }
  }
);
