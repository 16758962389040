import React from 'react';



  export default class ProfilePage extends React.Component {
    constructor(props) {
      super(props);
 
    }

    render() {
    
      return (
        <section className="user-profile">
         <div className={"background-container-" + Math.round( Math.random()*4) +" profile"}>
        <div class="form-container">
   <div className="form-element logo-element">
	      <p className="logo">&nbsp;</p>
  </div>
	
          <h1>Thank you for registering!</h1>
	  <h2>Check your email for next steps.</h2>
          <div>
           
         

        <a href="javascript:void(0)" onClick={() => this.props.auth.logout()}> Logout </a>
          </div>
  </div>
</div>
        </section>
      );
    }
  };

