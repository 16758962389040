import React from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { withAuth } from '@okta/okta-react';

var randomBackground = "background-container-" + Math.round( Math.random()*4) +" register";

export default withAuth(
  class LoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        sessionToken: null,
        error: null,
        username: '',
        password: ''
      };

      this.oktaAuth = new OktaAuth({ url: props.baseUrl });

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleUsernameChange = this.handleUsernameChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handleSubmit(e) {
      e.preventDefault();
      this.oktaAuth
        .signIn({
          username: this.state.username,
          password: this.state.password
        })
        .then(res =>
          this.setState({
            sessionToken: res.sessionToken
          })
        )
        .catch(err => {
          this.setState({ error: err.message });
          console.log(err.statusCode + ' error', err);
        });
    }

    handleUsernameChange(e) {
      this.setState({ username: e.target.value });
    }

    handlePasswordChange(e) {
      this.setState({ password: e.target.value });
    }

    render() {
      if (this.state.sessionToken) {
        this.props.auth.redirect({ sessionToken: this.state.sessionToken });
        return null;
      }
   
       const errorMessage = this.state.error ? (
       <div><span className="error-message">{this.state.error}</span> <p className="error-subtext">Please check your email address and password and try again.</p></div>
      ) : null;
	      
     

      return (
	      <div className={randomBackground}>
 <div className="form-container">
    
        <form onSubmit={this.handleSubmit}>
	       <div className="form-element logo-element">
	      <p className="logo">&nbsp;</p>
	      
	      <p>Login</p>
	      </div>
          {errorMessage}
          <div className="form-element">
            <label>Username:</label>
            <input
              id="username"
              placeholder="Your Email"
              type="text"
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
          </div>

          <div className="form-element">
            <label>Password:</label>
            <input
              id="password"
              placeholder="Password"
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </div>
	  <input id="submit" type="submit" value="Submit" />
          <div className="forgot-password">
          <a href="/passwordReset">Forgot Password?</a>
          <p className="form-sub-text">New to the Comms Portal?<a href="#"><a href="/register">Register</a></a><br/><br/><a href="mailto:support@allofuscomms.org">support@allofuscomms.org</a></p>
	      
           </div>
	      
          </form>
</div>	      
</div>
    );
    }
  }
);
