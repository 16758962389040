
import React from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';
import config from '../../app.config';
import Logout from './Logout';
var randomBackground = "background-container-" + Math.round( Math.random()*4) +" register";




var path = window.location.pathname;
console.log(path.replace('passwordrecovery/',''));
var pathkeys = path.split('/');
var uid = pathkeys[2];
var ft = pathkeys[3];

console.log("uid:"+uid);
console.log("ft:"+ft);

export default withAuth(
  class PasswordRecovery extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
  error: '',
  password_one: '',
  password_two: '',
  passwordError: '',
  passwordInvalid: '',
  preError: false,	      
  factorToken: ft,
  userId: uid,
  isLoaded: false,
        redirect: false,
        sessionToken: null
      };
        
      this.oktaAuth = new OktaAuth({ url: config.url });
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication();

      this.handleSubmit = this.handleSubmit.bind(this);
  
      this.handlePasswordOneChange = this.handlePasswordOneChange.bind(this);
      this.handlePasswordTwoChange = this.handlePasswordTwoChange.bind(this);
   
    }

    async checkAuthentication() {
      const sessionToken = await this.props.auth.getIdToken();
      if (sessionToken) {
        this.setState({ sessionToken });
      }
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    handlePasswordOneChange(e) {
      this.setState({ password_one: e.target.value });
    }
   
   handlePasswordTwoChange(e) {
      this.setState({ password_two: e.target.value });
    }
      
       validateFields = function () {
this.setState({ preError: false });
this.setState({ error: "" });
	
	console.log("here");
	
	  if(this.state.password_two == this.state.password_one){
	this.setState({ passwordInvalid: false });
		console.log("here 1");
		
		if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.state.password_one)) {
    			console.log("Valid");
		this.setState({ passwordInvalid: false });
		this.setState({ error: "" });	
			
	

                      //OK
                      fetch('/api/passwordupdate', {
                       method: 'POST',
                      headers: {
                      Accept: 'application/json',
                     'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(this.state)
                      })
                       .then(res => res.json())
                      //OK
                     .then(res =>{
                     if(res.ok){
                      console.log(res);
                      this.setState({ redirect: true });
		     }else{
		     this.setState({ redirect: true });
		     }
          
                      }) 
       
                          //OK
                          .catch(err => {
                          this.setState({ error: err.message });
                          console.log(err.statusCode + ' error', err);
			      
                          });
                          //OK
                       
		 this.setState({ redirect: true });	
			
		} else {
	        this.setState({ passwordInvalid: true });
		this.setState({ error: "Make sure your password meets minimum requirements!" });
   		 console.log("Invalid");
		}

	
        }else{
		
	this.setState({ error: "Make sure your password meets minimum requirements!" });
	this.setState({ passwordInvalid: true });
	this.setState({ preError: true });
	
	}
}
    
    
   
                   handleSubmit(e) {
                    e.preventDefault();
                    
			this.validateFields();	   
                       
                   }
 
    render() {
        
         const { error, isLoaded } = this.state;
    const { redirect } = this.state;
   
  
  
         if (redirect) {
     return <Redirect to='/recoverycomplete'/>;
}else{
    
    const errorMessage = this.state.error ? (
       <div><span className="error-message">{this.state.error}</span> <p className="error-subtext">Please check that your password meets the following requirements:1 Uppercase letter, 1 lowercase letter, one special character. At least 8 digits. </p></div>
      ) : null;
	
     return (
       
        <div className={randomBackground}>
        <div className="form-container pass-recover">
           {errorMessage}
        <form onSubmit={this.handleSubmit}>
        <div className="form-element logo-element">
        <p className="logo">&nbsp;</p>
        
        <p>Recover Password</p>
      
        <p></p>
       <span className="email-note">Enter a new password, <br />and confirm it below.<br /><br />Both passwords must match.</span>
        </div>
      
          <div className={this.state.passwordInvalid ? 'form-element password_one invalid' : 'form-element password_one'}>
          
            <label>Email:</label>
            <input
              type="password"
              id="password_one"
              placeholder="Password*"
        value={this.state.password_one}
              onChange={this.handlePasswordOneChange}
            />
	     </div>
	  <div className={this.state.passwordInvalid ? 'form-element password_two invalid' : 'form-element password_two'}>
         <input
              type="password"
              id="password_two"
              placeholder="Password*"
        value={this.state.password_two}
              onChange={this.handlePasswordTwoChange}
            />
          <span className="pass-text">Password requirements</span>
	      <ul className="pass-ul">
	      <li>At least 12 characters</li>
	      <li>A lowercase letter</li>
	      <li>An uppercase letter</li>
	      <li>A symbol (@, $, !, %, *, #, ?, &)</li>
	      <li>No parts of your username</li>
	      <li>Does not include your first name</li>
	      <li>Does not include your last name</li>
	      <li>Password can't be the same as your last 24 passwords</li>
	      <li>At least 2 hours must have elapsed since you last changed your password</li>
	      </ul>
          </div>
  
      <div className="form-element submit-element pass-recover">
              <span id="recover-required">* indicates a required field</span>
          <input type="submit" id="submit-recovery" value="Update Password" />
      
       <span id="recovery-login">Back to <a
              href="/profilelanding"
              >
              Login
            </a></span>
      </div>
        </form>
      </div>
      </div>
        
      )
    }
  

  }
     });


