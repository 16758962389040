import React from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { withAuth } from '@okta/okta-react';

import config from '../../app.config';
var randomBackground = "background-container-" + Math.round( Math.random()*4) +" register";

export default withAuth(
  class RecoveryConfirm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
	error: null,
        email: '',
        sessionToken: null
      };
        
      this.oktaAuth = new OktaAuth({ url: config.url });
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication();

      this.handleSubmit = this.handleSubmit.bind(this);
  
      this.handleEmailChange = this.handleEmailChange.bind(this);
   
    }

    async checkAuthentication() {
      const sessionToken = await this.props.auth.getIdToken();
      if (sessionToken) {
        this.setState({ sessionToken });
      }
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    handleEmailChange(e) {
      this.setState({ email: e.target.value });
    }
    
    
   
    handleSubmit(e) {
      e.preventDefault();
      fetch('/api/userfind', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
	      
	      .then(res =>{
	      if (res.ok) {

	this.props.history.push('/resetConfirm')
         return res.json();
		      
       } else {
          throw new Error('Something went wrong ...');
	   console.log("ERROR");
       }
          
      }) 
	     
	    
	     .catch(err => {
          this.setState({ error: err.message });
          console.log(err.statusCode + ' error', err);
        });
	    
        
        }
      
    

    render() {
      if (this.state.sessionToken) {
        this.props.auth.redirect({ sessionToken: this.state.sessionToken });
        return null;
      }
	    
	     const errorMessage = this.state.error ? (
       <div><span className="error-message">{this.state.error}</span> <p className="error-subtext">Please check your email address and password and try again.</p></div>
      ) : null;

      return (
	      <div className={randomBackground}>
	      <div className="form-container">
	      <div className="form-element logo-element"><p className="logo">&nbsp;</p></div>
        	<p>Your password recovery request has been received. Please check your email to verify the request and reset your password.</p>
	       <span className="login">Back to <a
              href="/profilelanding"
                >
              Login
            </a></span>
	    </div>
	    </div>
      );
    }
  }
);
